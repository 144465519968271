<template>
  <v-container fluid :style="{ backgroundColor: selectedColor }">
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:prepend>
        <v-icon icon="mdi mdi-format-list-bulleted" size="small"></v-icon>
      </template>
    </v-breadcrumbs>
    <v-row justify="center">
      <v-col cols="12" sm="9" md="6" class="text-center">
        <v-img
          class="mx-auto my-1"
          width="300px"
          aspect-ratio="16/9"
          :src="require('@/assets/images/vision-y-estrategia.png')"
        />
        <v-card elevation="3">
          <v-card-title>Registro de usuario</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="register" ref="form">
              <v-text-field
                v-model="instance_name"
                label="Nombre de la instancia evaluada"
                :prepend-icon="mdi.instance"
                :rules="[rules.required, rules.onlySpacesLettersNumbers]"
              ></v-text-field>
              <v-text-field
                v-model="email"
                label="Correo electrónico"
                :prepend-icon="mdi.email"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
              <v-text-field
                v-model="phone"
                label="Número telefónico"
                type="number"
                :prepend-icon="mdi.phone"
                :rules="[rules.required, rules.phone]"
              ></v-text-field>
              <v-file-input
                v-model="image"
                label="Imagen de perfil"
                :prepend-icon="mdi.image"
                :rules="[rules.file_undefined, rules.file_size]"
                show-size
                accept="image/png, image/jpeg, image/bmp"
              ></v-file-input>
              <v-text-field
                v-model="selectedColor"
                label="Seleccionar color"
                :prepend-icon="mdi.color"
                :rules="[rules.required]"
                readonly
              ></v-text-field>
              <v-container align="center">
                <v-color-picker
                  class="mb-2"
                  v-model="selectedColor"
                ></v-color-picker>
              </v-container>
              <v-text-field
                v-model="user_name"
                label="Nombre de usuario"
                :prepend-icon="mdi.user"
                :rules="[rules.required, mdi.onlyLettersNumbersAndDash]"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Contraseña"
                :prepend-icon="showPassword ? mdi.eye : mdi.eye_off"
                :rules="[rules.required, mdi.passwordLength]"
                @click:prepend="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
              ></v-text-field>
              <v-text-field
                v-model="confirmPassword"
                label="Confirmar contraseña"
                :prepend-icon="showConfirmPassword ? mdi.eye : mdi.eye_off"
                :rules="[rules.required, mdi.passwordLength]"
                @click:prepend="showConfirmPassword = !showConfirmPassword"
                :type="showConfirmPassword ? 'text' : 'password'"
              ></v-text-field>
              <v-btn
                color="#561C24"
                elevation="3"
                variant="outlined"
                type="submit"
                >Registrar</v-btn
              ><br /><br />
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { dataBaseAddUpdateItem } from "@/assets/js/index.js";
import { getFormattedDate } from "@/assets/js/index.js";

export default {
  name: "RegisterView",
  data() {
    return {
      itemUser: null,
      instance_name: "",
      email: "",
      phone: "",
      image: [],
      selectedColor: "#FFFFFF",
      user_name: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showConfirmPassword: false,
      mdi: {
        instance: "mdi mdi-office-building-outline",
        email: "mdi mdi-email",
        phone: "mdi mdi-phone",
        image: "mdi mdi-image-outline",
        color: "mdi mdi-palette-outline",
        user: "mdi mdi-account-outline",
        eye: "mdi mdi-eye-outline",
        eye_off: "mdi mdi-eye-off-outline",
      },
      rules: {
        required: (v) => !!v || "Este campo es requerido",
        email: (v) =>
          /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido",
        image: (v) =>
          !!v ||
          !v ||
          v[0].size < 2000000 ||
          "El tamaño de la imagen debe ser menor a 2 MB",
        onlySpacesLettersNumbers: (v) =>
          /^[A-Za-z0-9áéíóúÁÉÍÓÚüÜÑñ\s]+$/.test(v) ||
          "Este campo solo debe contener letras, números, espacios y acentos",
        onlyLettersNumbersAndDash: (v) =>
          /^[a-zA-Z0-9-_]*$/.test(v) ||
          "Este campo solo puede contener letras, números, guiones medios y guiones bajos",
        onlyLettersNumbers: (v) =>
          /^[A-Za-z0-9]+$/.test(v) ||
          "Este campo solo debe contener letras y números",
        passwordLength: (v) => v.length > 6 || 'La contraseña debe tener más de 6 caracteres',
        phone: (v) =>
          /^\d{10}$/.test(v) ||
          "Este campo debe contener exactamente 10 números",
        file_undefined: (v) =>
          typeof v[0] !== "undefined" || "Un archivo es requerido",
        file_size: (v) =>
          (typeof v[0] !== "undefined" && v[0].size < 2000000) ||
          "El tamaño del archivo debe ser menor a 2 MB",
      },
      breadCrumbsItems: [
        {
          title: "Lista de instancias evaluadas",
          disabled: false,
          href: "/#/instancias-evaluadas",
        },
        {
          title: "Registro",
          disabled: true,
        },
      ],
    };
  },
  methods: {
    async register() {
      //form meets validations
      const isValid = await this.$refs.form.validate();
      if (!isValid.valid) {
        window.alert("Verifica que todos los campos sean correctos");
        return;
      }
      // do not repeat username validation
      var tempuserexistanswer = "";
      await fetch(this.$store.state.apiUrl + "usernameExist/" + this.user_name)
        .then((response) => response.json())
        .then((data) => {
          tempuserexistanswer = data.body;
        })
        .catch((error) => {
          tempuserexistanswer = error;
        });
      if (tempuserexistanswer.error) {
        window.alert("Error en la base de datos, favor de intentar de nuevo");
        return;
      }
      if (tempuserexistanswer === true) {
        window.alert(
          "El nombre de usuario no se debe repetir con otros registros"
        );
        return;
      }
      //check password
      if (this.password != this.confirmPassword) {
        window.alert("Las contraseñas deben coincidir");
        return;
      }
      // concatenate
      const newFileName = `${getFormattedDate()}_${this.image[0].name}`;
      const formData = new FormData();
      formData.append("file", this.image[0], newFileName);
      // Send the request to server
      try {
        fetch(this.$store.state.apiUrl + "upload", {
          method: "POST",
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Error al cargar el archivo");
            }
            return response.text();
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            window.alert(
              "Ocurrió un error al cargar el archivo: " + error.message
            );
            return;
          });
      } catch (error) {
        console.error("Error:", error);
        window.alert(
          "Ocurrió un error al realizar la solicitud de carga de imagen: " +
            error.message
        );
        return;
      }

      // asign items
      this.itemUser = {
        instance_name: this.instance_name,
        email: this.email,
        phone_number: this.phone,
        image_path: newFileName, // set newfilename
        color: this.selectedColor,
        user_name: this.user_name,
        encrypted_password: this.password,
        boolean_admin_permissions: 0,
        boolean_status: 1,
      };
      var requestAnswer = "";
      //api request register
      try {
        requestAnswer = await dataBaseAddUpdateItem(
          this.$store.state.apiUrl + "users/register",
          this.itemUser
        );
      } catch (error) {
        console.error("Error:", error);
        window.alert(
          "Ocurrió un error al realizar la solicitud de registro del usuario: " +
            error.message
        );
        return;
      }
      // check if api request answer is correct
      if (requestAnswer.error) {
        window.alert("Error al registrar usuario, favor de intentar de nuevo");
        return;
      }
      // api request send_email
      await fetch(this.$store.state.apiUrl + "send_email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          templateId: this.$store.state.templateID,
          dynamicTemplateData: {
            subject:
              "Acceso a la plataforma del Sistema para la gestión del PAE",
            instance_name: this.itemUser.instance_name,
            email_body_line01:
              "Aquí tienes la liga y credenciales correspondientes para el acceso a la plataforma del Sistema para la gestión del PAE",
            email_body_line02: "Nombre de usuario: " + this.itemUser.user_name,
            email_body_line03:
              "Contraseña: " + this.itemUser.encrypted_password,
          },
          to: this.itemUser.email,
          from: this.$store.state.gobernovaEmailFrom,
          replyTo: this.gobernovaEmailFrom,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            window.alert("Error al enviar el correo electrónico");
            throw new Error("Error al enviar el correo electrónico");
          }
          //window.alert("Correo electrónico enviado con éxito");
        })
        .catch((error) => {
          console.error("Error al enviar el correo electrónico:", error);
        });
      // everything is correct
      this.$nextTick(() => {
        //this.selectedFileItem = structuredClone({});
        this.image = [];
      });
      window.alert("Usuario registrado con exito");
      this.$router.push({ name: "EvaluatedInstances" });
    },
  },
};
</script>
