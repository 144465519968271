import { createStore } from "vuex";

const store = createStore({
  state() {
    return {
      //test
      apiUrl: "https://badmin.visionyestrategiaevalua.com/api/",
      //apiUrl: "http://localhost:3003/api/",
      visionPhoneNumber: "7223875761",
      templateID: "d-7f6a862245b643e89a158dc68fe980a7",
      visionPaeEmail: "pae@visionyestrategia.com.mx",
      visionDireccionGeneralEmail: "direcciongeneral@visionyestrategia.com.mx",
      visiondirecciondeoperacionesEmail:
        "direcciondeoperaciones@visionyestrategia.com.mx",
      visionGmail: "sistemadeintegracionpae@gmail.com",
      visionEmailFrom: "pae@visionyestrategia.com.mx",
      count: localStorage.getItem("count")
        ? parseInt(localStorage.getItem("count"))
        : 0,
      user: localStorage.getItem("user") ? localStorage.getItem("user") : "",
    };
  },
  mutations: {
    increment(state) {
      state.count++;
      localStorage.setItem("count", state.count);
    },
    reset(state) {
      state.count = 0;
      localStorage.setItem("count", state.count);
    },
    setUser(state, user) {
      state.user = user;
      localStorage.setItem("user", user);
    },
    clearUser(state) {
      state.user = "";
      localStorage.removeItem("user");
    },
  },
});

export default store;
