<template>
  <v-container>
    <v-breadcrumbs :items="breadCrumbsItems">
      <template v-slot:prepend>
        <v-icon icon="mdi mdi-format-list-bulleted" size="small"></v-icon>
      </template>
    </v-breadcrumbs>
    <div v-if="boolean_empty_info" cols="12" md="10" sm="10">
      <span>Datos no disponibles o no asignados</span>
    </div>
    <v-row
      v-else
      v-for="(evaluation, evaluationId) in groupedData"
      :key="evaluationId"
      @update:options="loadData"
    >
      <v-col>
        <h1 class="text-uppercase text-justify">{{ evaluationId }}</h1>
        Descarga los terminos de referencia:<br />
        <v-icon class="mx-1" size="small" @click="downloadFile(evaluationFile)">
          mdi-download
        </v-icon>
        <v-btn
          size="md"
          style="opacity: 1"
          variant="plain"
          @click="downloadFile(evaluationFile)"
        >
          {{ getFileName(evaluationFile) }}
        </v-btn>
        <v-expansion-panels class="my-5" variant="inset">
          <v-expansion-panel title="Resumen de la evaluación">
            <v-expansion-panel-text>
              <v-row justify="center" align="center">
                <v-col
                  cols="4"
                  xs="4"
                  sm="3"
                  md="2"
                  lg="1"
                  xl="1"
                  xxl="1"
                  v-for="chip in chips_questions_status"
                  :key="chip.key"
                  class="text-center"
                >
                  <v-chip :text="chip.key + ''" :color="chip.color"></v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-row
          v-for="(section, sectionId, index) in evaluation"
          :key="sectionId"
        >
          <v-col>
            <h3
              v-if="evaluationId != sectionId"
              class="text-uppercase text-justify"
            >
              {{ String.fromCharCode(65 + index) }}.
              {{ sectionId }}
            </h3>
            <v-row v-for="(question, questionId) in section" :key="questionId">
              <v-container>
                <v-card class="elevation-3">
                  <v-col>
                    <v-container>
                      <div
                        class="text-justify"
                        v-html="
                          `<strong>${
                            question.question_number
                          }.</strong> ${question.question_body.replace(
                            /\n/g,
                            '<br>'
                          )}`
                        "
                      ></div>
                      <v-divider class="my-3"></v-divider>
                      <v-row class="mb-2">
                        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <v-btn
                            color="#1B5E20"
                            elevation="3"
                            variant="tonal"
                            :prepend-icon="mdi.file"
                            @click="
                              openDialogFileForm(
                                '',
                                question.user_question_id,
                                1,
                                question.question_number
                              )
                            "
                            >Agregar archivo</v-btn
                          >
                        </v-col>
                        <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                          <v-btn
                            color="#1B5E20"
                            elevation="3"
                            variant="tonal"
                            :prepend-icon="mdi.link"
                            @click="
                              openDialogLinkForm(
                                '',
                                question.user_question_id,
                                1,
                                question.question_number
                              )
                            "
                            >Agregar enlace</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          class="py-0"
                          v-for="(file, index) in question.files"
                          :key="index"
                        >
                          <template v-if="file.file_path == 'no files'">
                            Sin archivos/enlaces
                          </template>

                          <template v-else>
                            <v-btn
                              :disabled="boolean_all_approved"
                              class="mx-1 my-0"
                              variant="text"
                              icon
                              @click="
                                openDialogFileDelete(
                                  file.file_id,
                                  file.file_path,
                                  question.user_question_id,
                                  0
                                )
                              "
                            >
                              <v-icon class="mdi mdi-delete" />
                            </v-btn>
                            <v-btn
                              v-if="rules.url_format_boolean(file.file_path)"
                              class="mx-1 my-0"
                              variant="text"
                              icon
                              @click="openLink(file.file_path)"
                            >
                              <v-icon class="mdi mdi-open-in-new" />
                            </v-btn>
                            <v-btn
                              v-else
                              class="mx-1 my-0"
                              variant="text"
                              icon
                              @click="downloadFile(file.file_path)"
                            >
                              <v-icon class="mdi mdi-download" />
                            </v-btn>
                            <template
                              v-if="rules.url_format_boolean(file.file_path)"
                            >
                              <a
                                :href="file.file_path"
                                style="color: black"
                                target="_blank"
                                >{{ file.file_path }}</a
                              >
                            </template>
                            <template v-else>
                              <v-btn
                                size="md"
                                style="opacity: 1"
                                variant="plain"
                                @click="downloadFile(file.file_path)"
                              >
                                {{ file.file_path }}
                              </v-btn>
                            </template>
                          </template>
                        </v-col>
                      </v-row>
                      <v-divider class="my-3"></v-divider>
                      <p>Comentarios y/o fuentes de información de la instancia evaluada</p>
                      <v-btn
                        v-if="!boolean_admin"
                        @click="openDialogUserQuestionForm(question)"
                        class="mx-1"
                        variant="text"
                        icon
                      >
                        <v-icon class="mdi mdi-pencil" />
                      </v-btn>
                      <v-chip
                        v-if="
                          question.question_comment_evaluated_instance === ''
                        "
                        color="gray"
                        prepend-icon="mdi mdi-asterisk-circle-outline"
                        text="SIN COMENTARIOS"
                        class="text-uppercase"
                        size="small"
                        label
                      ></v-chip>
                      <v-chip
                        v-else
                        color="gray"
                        prepend-icon="di mdi-bell-circle-outline"
                        :text="question.question_comment_evaluated_instance"
                        class="text-uppercase"
                        size="small"
                        label
                      ></v-chip>
                      <v-divider class="my-2"></v-divider>
                      <p>Comentarios del equipo evaluador</p>
                      <v-btn
                        @click="openDialogUserQuestionForm(question)"
                        v-if="boolean_admin"
                        class="mx-1"
                        variant="text"
                        icon
                      >
                        <v-icon class="mdi mdi-pencil" />
                      </v-btn>
                      <v-chip
                        v-if="
                          question.question_comment_evaluator === 'sin revisar'
                        "
                        color="gray"
                        prepend-icon="mdi mdi-asterisk-circle-outline"
                        text="sin revisar"
                        class="text-uppercase"
                        size="small"
                        label
                      ></v-chip>
                      <v-chip
                        v-else-if="
                          question.question_comment_evaluator === 'aprobado'
                        "
                        color="green"
                        prepend-icon="mdi mdi-check-circle-outline"
                        text="aprobado"
                        class="text-uppercase"
                        size="small"
                        label
                      ></v-chip>
                      <v-chip
                        v-else
                        color="orange"
                        prepend-icon="mdi mdi-bell-circle-outline"
                        :text="question.question_comment_evaluator.toString()"
                        class="text-uppercase"
                        size="small"
                        label
                      ></v-chip>
                      <br />
                    </v-container>
                  </v-col>
                </v-card>
              </v-container>
            </v-row>
            <br />
          </v-col>
        </v-row>
        <v-col v-if="this.messageText != ''">
          <div v-html="`${this.messageText.replace(/\n/g, '<br>')}`" />
          <br />
          <p>¿Enviar notificación sobre los cambios realizados?</p>
          <v-btn
            color="#561C24"
            elevation="3"
            variant="outlined"
            @click="openDialogSendMessage()"
            >Enviar</v-btn
          >
        </v-col>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog
    v-model="dialogFileDelete"
    max-width="90%"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title class="text-h5"
        >¿Estás seguro de que quieres eliminar este registro?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="closeDialogFileDelete"
          >Cancelar</v-btn
        >
        <v-btn
          color="red-accent-4"
          variant="text"
          @click="confirmDialogFileDelete"
          >Borrar</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogFileForm"
    max-width="90%"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Agregar archivo a la pregunta no.
          {{ this.selectedQuestionNumber }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-form ref="formFile" fast-fail>
                <v-file-input
                  v-model="file"
                  label="Nuevo archivo"
                  :rules="[rules.file_undefined, rules.file_size]"
                  show-size
                  accept="image/png, image/jpeg, image/bmp, application/pdf, .docx, .odt, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ></v-file-input>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="closeDialogFileForm"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="confirmDialogFileForm"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogLinkForm"
    max-width="90%"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Agregar enlace a la pregunta no.
          {{ this.selectedQuestionNumber }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-form ref="formLink" fast-fail>
                <v-text-field
                  v-model="link"
                  label="Enlace"
                  :rules="[rules.required, rules.url_format]"
                  :prepend-icon="mdi.link"
                  clearable
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="closeDialogLinkForm"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="confirmDialogLinkForm"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogUserQuestionForm"
    max-width="90%"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title>
        <span class="text-h5"
          >Editar comentario de pregunta no.
          {{ this.selectedQuestionNumber }}</span
        >
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" md="12" sm="12">
              <template v-if="boolean_admin">
                <v-chip
                  color="gray"
                  prepend-icon="mdi mdi-asterisk-circle-outline"
                  class="text-uppercase mx-2 my-2"
                  @click="setStatusValue('sin revisar')"
                  >sin revisar</v-chip
                >
                <v-chip
                  color="green"
                  prepend-icon="mdi mdi-check-circle-outline"
                  class="text-uppercase mx-2 my-2"
                  @click="setStatusValue('aprobado')"
                  >aprobado</v-chip
                >
                <v-chip
                  color="orange"
                  prepend-icon="mdi mdi-bell-circle-outline"
                  class="text-uppercase mx-2 my-2"
                  @click="setStatusValue('')"
                  >comentario</v-chip
                >
              </template>
              <v-form ref="formUserQuestion" fast-fail>
                <v-text-field
                  v-if="boolean_admin"
                  v-model="selectedUserQuestionItem.comment_evaluator"
                  label="Comentario"
                  :disabled="statusFieldIsDisabled"
                  :rules="[rules.required, rules.alphanumeric]"
                  clearable
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="selectedUserQuestionItem.comment_evaluated_instance"
                  label="Comentario"
                  :rules="[rules.alphanumeric]"
                  clearable
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="closeDialogUserQuestionForm"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="confirmDialogUserQuestionForm"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogSendMessage"
    max-width="90%"
    transition="dialog-top-transition"
  >
    <v-card>
      <v-card-title class="text-h5"
        >Se ha enviado con exito un correo electrónico</v-card-title
      >
      <v-card-text>
        <!--
        <div v-html="`${this.messageText.replace(/\n/g, '<br>')}`" />
          -->
        >¿Enviar mensaje de WhatsApp adicionalmente?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="#25D366"
          variant="text"
          prepend-icon="mdi mdi-whatsapp"
          @click="confirmDialogSendMessage()"
          >Whats App</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          color="grey-darken-2"
          variant="text"
          @click="closeDialogSendMessage()"
          >Cancelar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { q_f_per_user_per_evaluation } from "@/assets/js/index.js";
import { dataBaseAddUpdateItem } from "@/assets/js/index.js";
import { databaseSelectPhoneAndEmail } from "@/assets/js/index.js";
import { getFormattedDate } from "@/assets/js/index.js";

export default {
  name: "UserEvaluation",

  async mounted() {
    //
    this.filter_user_id = sessionStorage.getItem("filter_user_id");
    this.filter_instance_name = sessionStorage.getItem("filter_instance_name");
    this.filter_evaluation_id = sessionStorage.getItem("filter_evaluation_id");

    if (this.filter_user_id === null || this.filter_user_id === undefined) {
      if (this.getStoreUser != "") {
        this.$router.push({ name: "EvaluationsList" });
      } else {
        this.$router.push({ name: "Login" });
      }
    }

    if (
      this.filter_evaluation_id === null ||
      this.filter_evaluation_id === undefined
    ) {
      if (this.getStoreUser != "") {
        if (this.getStoreUser.boolean_admin_permissions === 1) {
          this.$router.push({ name: "EvaluatedInstances" });
        } else if (this.getStoreUser.boolean_admin_permissions === 0) {
          this.$router.push({ name: "EvaluationsList" });
        }
      } else {
        this.$router.push({ name: "Login" });
      }
    }

    // get database data
    await this.loadData();

    //
    if (
      this.groupedData === undefined ||
      this.groupedData === null ||
      this.groupedData === "" ||
      (typeof this.groupedData === "string" && this.groupedData.trim() === "")
    ) {
      this.boolean_empty_info = true;
    } else {
      this.boolean_empty_info = false;
    }
    //
    if (this.getStoreUser != "") {
      if (this.getStoreUser.boolean_admin_permissions === 1) {
        // set ui permissions
        this.boolean_admin = true;
        // set admin breadCrumbsItems
        this.breadCrumbsItems = [
          {
            title: "Lista de instancias evaluadas",
            disabled: false,
            href: "/#/instancias-evaluadas",
          },
          {
            title:
              "Términos de referencia de la evaluación - " +
              this.filter_instance_name,
            disabled: false,
            href: "/#/lista-de-evaluaciones",
          },
          {
            title: Object.keys(this.groupedData)[0],
            disabled: true,
          },
        ];
        // set message values
        var answertemp = await databaseSelectPhoneAndEmail(
          this.$store.state.apiUrl +
            "selectPhoneAndEmail/" +
            this.filter_user_id,
          this.selectedUserQuestionItem
        );
        //
        if (answertemp.error) {
          window.alert("Error en la base de datos, favor de intentar de nuevo");
          return;
        }
        this.messageTo =
          answertemp.body[0].phone_number + "_" + answertemp.body[0].email;
        this.messageToName = this.filter_instance_name;
        this.messageReplyTo = this.$store.state.visionGmail;
        this.messageEvaluationName = Object.keys(this.groupedData)[0];
        this.messageText = "";
      } else if (this.getStoreUser.boolean_admin_permissions === 0) {
        // set ui permissions
        this.boolean_admin = false;
        // asign admin breadCrumbsItems
        this.breadCrumbsItems = [
          {
            title: "Términos de referencia de la evaluación",
            disabled: false,
            href: "/#/lista-de-evaluaciones",
          },
          {
            title: Object.keys(this.groupedData)[0],
            disabled: true,
          },
        ];
        // set message values
        this.messageTo =
          this.$store.state.visionPhoneNumber +
          "_" +
          this.$store.state.visionGmail;
        this.messageToName = "Vision y Estrategia";
        this.messageReplyTo = this.getStoreUser.email;
        this.messageEvaluationName =
          Object.keys(this.groupedData)[0] +
          ", " +
          this.getStoreUser.instance_name;
        this.messageText = "";
      }
    }
  },

  computed: {
    getStoreUser() {
      const userState = this.$store.state.user;
      if (userState != "") {
        const user = JSON.parse(userState);
        return user;
      } else {
        return "";
      }
    },
  },

  data() {
    return {
      filter_user_id: null,
      filter_instance_name: null,
      filter_evaluation_id: null,
      boolean_admin: null,
      boolean_all_approved: null,
      groupedData: "",
      groupedByEvaluation: {},
      dialogFileForm: false,
      dialogLinkForm: false,
      dialogFileDelete: false,
      dialogUserQuestionForm: false,
      dialogSendMessage: false,
      messageText: "",
      messageTo: "",
      messageReplyTo: "",
      messageEvaluationName: "",
      evaluationFile: "",
      messageToName: "",
      selectedQuestionNumber: "",
      selectedFileItem: {},
      selectedUserQuestionItem: {},
      file: [],
      boolean_empty_info: true,
      breadCrumbsItems: [],
      chips_questions_status: [],
      link: "",
      rules: {
        required: (v) => !!v || "Este campo es requerido",
        file_undefined: (v) =>
          typeof v[0] !== "undefined" || "Un archivo es requerido",
        file_size: (v) =>
          (typeof v[0] !== "undefined" && v[0].size < 150 * 1024 * 1024) ||
          "El tamaño del archivo debe ser menor a 150 MB",
        alphanumeric: (v) =>
          /^[a-zA-Z0-9.,;:\-()¿?¡! áéíóúÁÉÍÓÚñÑüÜ]*$/.test(v) ||
          "Este campo solo puede contener letras, números, puntos, comas, puntos y comas, dos puntos, guiones medios, paréntesis, signos de interrogación, signos de admiración, espacios, letras acentuadas (áéíóúÁÉÍÓÚ), ñ, y letras u con diéresis (üÜ).",
        url_format: (v) =>
          /^(ftp|http|https):\/\/[^ "]+$/.test(v) ||
          "Por favor, introduce un enlace válido",
        url_format_boolean: (v) => /^(ftp|http|https):\/\/[^ "]+$/.test(v),
      },
      mdi: {
        instance: "mdi mdi-office-building-outline",
        email: "mdi mdi-email",
        phone: "mdi mdi-phone",
        image: "mdi mdi-image-outline",
        color: "mdi mdi-palette-outline",
        user: "mdi mdi-account-outline",
        eye: "mdi mdi-eye-outline",
        eye_off: "mdi mdi-eye-off-outline",
        file: "mdi mdi-paperclip",
        link: "mdi mdi-link",
      },
    };
  },

  methods: {
    closeDialogFileDelete() {
      this.$nextTick(() => {
        this.selectedFileItem = structuredClone({});
        this.file = [];
      });
      this.dialogFileDelete = false;
    },

    closeDialogFileForm() {
      this.$nextTick(() => {
        this.selectedFileItem = structuredClone({});
        this.file = [];
        this.link = "";
      });
      this.dialogFileForm = false;
    },

    closeDialogLinkForm() {
      this.$nextTick(() => {
        this.selectedFileItem = structuredClone({});
        this.file = [];
        this.link = "";
      });
      this.dialogLinkForm = false;
    },

    closeDialogUserQuestionForm() {
      this.$nextTick(() => {
        this.selectedUserQuestionItem = structuredClone({});
      });
      this.dialogUserQuestionForm = false;
    },

    closeDialogSendMessage() {
      this.$nextTick(() => {
        this.messageText = structuredClone("");
        this.messageTo = structuredClone("");
        this.messageToName = structuredClone("");
        this.messageReplyTo = structuredClone("");
        this.messageEvaluationName = structuredClone("");
        this.selectedQuestionNumber = structuredClone("");
      });
      this.dialogSendMessage = false;
    },

    async confirmDialogFileDelete() {
      const answertemp = await dataBaseAddUpdateItem(
        this.$store.state.apiUrl + "files/update",
        this.selectedFileItem
      );
      //
      if (answertemp.error) {
        window.alert("Error en la base de datos, favor de intentar de nuevo");
        return;
      }
      this.closeDialogFileDelete();
      this.loadData();
    },

    async confirmDialogFileForm() {
      const isValid = await this.$refs.formFile.validate();
      if (!isValid.valid) {
        window.alert("Verifica que los datos sean correctos");
        return;
      }
      // concatenate
      const newFileName = `${getFormattedDate()}_${this.file[0].name}`;
      const formData = new FormData();
      //formData.append("file", this.file[0], newFileName);
      formData.append(
        "file",
        new Blob([this.file[0]], { type: this.file[0].type }),
        newFileName
      );

      // Send the request to server
      fetch(this.$store.state.apiUrl + "upload", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error al cargar el archivo");
          }
          return response.text();
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      // set newfilename
      this.selectedFileItem.file_path = newFileName;
      // add new register to database
      const answertemp = await dataBaseAddUpdateItem(
        this.$store.state.apiUrl + "files/add",
        this.selectedFileItem
      );
      //
      if (answertemp.error) {
        window.alert("Error en la base de datos, favor de intentar de nuevo");
        return;
      }
      // concat message
      this.messageText +=
        "Nuevo archivo/enlace agregado (" +
        newFileName +
        ") en la pregunta " +
        this.selectedQuestionNumber +
        ". \n";
      //
      this.closeDialogFileForm();
      this.loadData();
    },

    async confirmDialogLinkForm() {
      const isValid = await this.$refs.formLink.validate();
      if (!isValid.valid) {
        window.alert("Verifica que los datos sean correctos");
        return;
      }
      // set newfilename
      this.selectedFileItem.file_path = this.link;
      // add new register to database
      const answertemp = await dataBaseAddUpdateItem(
        this.$store.state.apiUrl + "files/add",
        this.selectedFileItem
      );
      //
      if (answertemp.error) {
        window.alert("Error en la base de datos, favor de intentar de nuevo");
        return;
      }
      // concat message
      this.messageText +=
        "Nuevo archivo/enlace agregado (" +
        this.link +
        ") en la pregunta " +
        this.selectedQuestionNumber +
        ". \n";
      //
      this.closeDialogLinkForm();
      this.loadData();
    },

    async confirmDialogUserQuestionForm() {
      const isValid = await this.$refs.formUserQuestion.validate();
      if (!isValid.valid) {
        window.alert("Verifica los datos sean correctos");
        return;
      }
      var answertemp = await dataBaseAddUpdateItem(
        this.$store.state.apiUrl + "user_questions/update",
        this.selectedUserQuestionItem
      );
      //
      if (answertemp.error) {
        window.alert("Error en la base de datos, favor de intentar de nuevo");
        return;
      }
      // concat message
      this.messageText +=
        "Nuevo comentario (" +
        this.selectedUserQuestionItem.comment_evaluator +
        ") en la pregunta " +
        this.selectedQuestionNumber +
        ". \n";
      //
      this.closeDialogUserQuestionForm();
      this.loadData();
    },

    async confirmDialogSendMessage() {
      this.sendWhats();
      this.closeDialogSendMessage();
    },

    sendWhats() {
      this.messageText =
        "Hola " +
        ", hay cambios en " +
        this.messageEvaluationName +
        ". Revisa tu plataforma. https://dev.visionyestrategiaevalua.com/#/";
      window.open(
        `https://wa.me/${this.messageTo.split("_")[0]}?text=${
          this.messageText
        }`,
        "_blank"
      );
    },

    async sendEmail() {
      await fetch(this.$store.state.apiUrl + "send_email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          templateId: this.$store.state.templateID,
          dynamicTemplateData: {
            subject: this.messageEvaluationName,
            instance_name: this.messageToName,
            email_body_line01:
              "Hay cambios en " + this.messageEvaluationName + ".",
            //email_body_line02: this.messageText,
            email_body_line02: "",
            email_body_line03: "Revisa tu plataforma.",
            email_body_line04: "",
          },
          to: this.messageTo.split("_")[1],
          from: this.$store.state.visionEmailFrom,
          replyTo: this.messageReplyTo,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            //window.alert("Error al enviar el correo electrónico");
            throw new Error("Error al enviar el correo electrónico");
          }
          //window.alert("Correo electrónico enviado con exito");
        })
        .catch((error) => {
          console.error("Error al enviar el correo electrónico:", error);
        });
    },

    openLink(url) {
      window.open(url, "_blank");
    },

    openDialogFileForm(
      file_path,
      user_question_id,
      boolean_status,
      question_number
    ) {
      this.selectedQuestionNumber = question_number;
      this.selectedFileItem = structuredClone({
        file_path: file_path,
        user_question_id: user_question_id,
        boolean_status: boolean_status,
      });
      this.dialogFileForm = true;
    },

    openDialogLinkForm(
      file_path,
      user_question_id,
      boolean_status,
      question_number
    ) {
      this.selectedQuestionNumber = question_number;
      this.selectedFileItem = structuredClone({
        file_path: file_path,
        user_question_id: user_question_id,
        boolean_status: boolean_status,
      });
      this.dialogLinkForm = true;
    },

    openDialogFileDelete(id, file_path, user_question_id, boolean_status) {
      this.selectedFileItem = structuredClone({
        id: id,
        file_path: file_path,
        user_question_id: user_question_id,
        boolean_status: boolean_status,
      });
      this.dialogFileDelete = true;
    },

    openDialogUserQuestionForm(question) {
      this.selectedQuestionNumber = question.question_number;
      this.selectedUserQuestionItem = structuredClone({
        id: question.user_question_id,
        comment_evaluator: question.question_comment_evaluator,
        comment_evaluated_instance:
          question.question_comment_evaluated_instance,
        user_id: question.user_id,
        question_id: question.question_id,
        boolean_status: 1,
      });
      if (
        question.comment_evaluator === "sin revisar" ||
        question.comment_evaluator === "aprovado"
      ) {
        this.statusFieldIsDisabled = true;
      } else {
        this.statusFieldIsDisabled = false;
      }
      this.dialogUserQuestionForm = true;
    },

    openDialogSendMessage() {
      this.sendEmail();
      this.dialogSendMessage = true;
    },

    async downloadFile(fileUrl) {
      try {
        const encodedFilename = encodeURIComponent(fileUrl);
        const url = this.$store.state.apiUrl + "download/" + encodedFilename;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Error al descargar el archivo");
        }
        const blob = await response.blob();
        const tempFileUrl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = tempFileUrl;
        //link.setAttribute("download", fileUrl.split("_")[2]);
        link.setAttribute("download", this.getFileName(fileUrl));
        // simulate click
        document.body.appendChild(link);
        link.click();
        // Release the blob URL
        window.URL.revokeObjectURL(tempFileUrl);
      } catch (error) {
        console.error("Error al descargar el archivo:", error);
      }
    },

    getFileName(filePath) {
      //return filePath.substring(filePath.lastIndexOf("/") + 1);
      //return filePath.substring(17, filePath.length);
      return decodeURIComponent(filePath.substring(17, filePath.length));
    },

    setStatusValue(value) {
      if (value === "sin revisar") {
        this.selectedUserQuestionItem.comment_evaluator = "sin revisar";
        this.statusFieldIsDisabled = true;
      } else if (value === "aprobado") {
        this.selectedUserQuestionItem.comment_evaluator = "aprobado";
        this.statusFieldIsDisabled = true;
      } else {
        this.selectedUserQuestionItem.comment_evaluator = "";
        this.statusFieldIsDisabled = false;
      }
    },

    async loadData() {
      //load items_e_q_f
      this.items_e_q_f = await q_f_per_user_per_evaluation(
        this.$store.state.apiUrl +
          "q_f_per_user_per_evaluation/" +
          this.filter_user_id +
          "/" +
          this.filter_evaluation_id
      );
      //
      if (this.items_e_q_f.error) {
        window.alert("Error en la base de datos, favor de intentar de nuevo");
        this.$router.push({ name: "DataBaseError" });
      }
      // groupByEvaluationAndSectionAndQuestion
      this.groupedData = this.groupByEvaluationAndSectionAndQuestion(
        this.items_e_q_f
      );

      //calculateApprovalPercentage
      if (this.calculateApprovalPercentage(this.groupedData) == 100) {
        this.boolean_all_approved = true;
      } else {
        this.boolean_all_approved = false;
      }
      //
      this.chips_questions_status = this.generateChips(this.groupedData);
    },
    // Function to group by section and by question
    groupBySection(evaluationData) {
      const groupedBySection = {};
      evaluationData.forEach((item) => {
        const {
          section_id,
          section_name,
          question_id,
          question_body,
          user_question_id,
          question_comment_evaluator,
          question_comment_evaluated_instance,
          question_number,
        } = item;
        //const key = `${section_id}-${section_name}`;
        var temp = `${section_id}`; //for now I don't need the ID
        temp = ""; //for now I don't need the ID
        const key = `${section_name}` + temp;
        if (!groupedBySection[key]) {
          groupedBySection[key] = {};
        }
        if (!groupedBySection[key][question_id]) {
          groupedBySection[key][question_id] = {
            question_id,
            user_question_id,
            question_body,
            question_comment_evaluator,
            question_comment_evaluated_instance,
            question_number,
            files: [],
          };
        }
        groupedBySection[key][question_id].files.push({
          file_id: item.file_id,
          file_path: item.file_path,
        });
      });
      return groupedBySection;
    },

    gotohome() {
      if (this.getStoreUser != "") {
        if (this.getStoreUser.boolean_admin_permissions === 1) {
          this.$router.push({ name: "EvaluatedInstances" });
        } else if (this.getStoreUser.boolean_admin_permissions === 0) {
          this.$router.push({ name: "EvaluationsList" });
        }
      }
    },

    // Function to group by evaluation, by section and by question
    groupByEvaluationAndSectionAndQuestion(evaluationData) {
      const groupedByEvaluation = {};
      evaluationData.forEach((item) => {
        const { evaluation_name, evaluation_file } = item;
        //const key = `${evaluation_id}-${evaluation_name}`;
        this.evaluationFile = evaluation_file;
        const key = `${evaluation_name}`;
        if (!groupedByEvaluation[key]) {
          groupedByEvaluation[key] = [];
        }
        groupedByEvaluation[key].push(item);
      });
      // Group by section within each assessment
      for (const key in groupedByEvaluation) {
        if (Object.prototype.hasOwnProperty.call(groupedByEvaluation, key)) {
          groupedByEvaluation[key] = this.groupBySection(
            groupedByEvaluation[key]
          );
        }
      }
      return groupedByEvaluation;
    },

    calculateApprovalPercentage(groupedData) {
      let totalQuestions = 0;
      let approvedQuestions = 0;
      // Iterar sobre cada evaluación
      for (const evaluationKey in groupedData) {
        if (Object.prototype.hasOwnProperty.call(groupedData, evaluationKey)) {
          const evaluation = groupedData[evaluationKey];
          // Iterar sobre cada sección dentro de la evaluación
          for (const sectionKey in evaluation) {
            if (Object.prototype.hasOwnProperty.call(evaluation, sectionKey)) {
              const section = evaluation[sectionKey];

              // Iterar sobre cada pregunta dentro de la sección
              for (const questionKey in section) {
                if (
                  Object.prototype.hasOwnProperty.call(section, questionKey)
                ) {
                  const question = section[questionKey];

                  // Incrementar el total de preguntas
                  totalQuestions++;

                  // Verificar si la pregunta está aprobada
                  if (question.question_comment_evaluator === "aprobado") {
                    approvedQuestions++;
                  }
                }
              }
            }
          }
        }
      }
      // Calcular el porcentaje de preguntas aprobadas
      const approvalPercentage = (approvedQuestions / totalQuestions) * 100;
      return approvalPercentage;
    },

    generateChips(groupedData) {
      let chips = [];
      // Iterar sobre cada evaluación
      for (const evaluationKey in groupedData) {
        if (Object.prototype.hasOwnProperty.call(groupedData, evaluationKey)) {
          const evaluation = groupedData[evaluationKey];
          // Iterar sobre cada sección dentro de la evaluación
          for (const sectionKey in evaluation) {
            if (Object.prototype.hasOwnProperty.call(evaluation, sectionKey)) {
              const section = evaluation[sectionKey];
              // Iterar sobre cada pregunta dentro de la sección
              for (const questionKey in section) {
                if (
                  Object.prototype.hasOwnProperty.call(section, questionKey)
                ) {
                  const question = section[questionKey];
                  // Verificar si la pregunta está aprobada
                  if (question.question_comment_evaluator === "sin revisar") {
                    chips.push({
                      key: question.question_number,
                      color: "gray",
                    });
                  } else if (
                    question.question_comment_evaluator === "aprobado"
                  ) {
                    chips.push({
                      key: question.question_number,
                      color: "green",
                    });
                  } else {
                    chips.push({
                      key: question.question_number,
                      color: "orange",
                    });
                  }
                }
              }
            }
          }
        }
      }
      return chips;
    },
  },
};
</script>
