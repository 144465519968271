<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="9" md="6" class="text-center">
        <v-card elevation="3">
          <v-img
            class="mx-auto my-1"
            width="300px"
            aspect-ratio="16/9"
            :src="require('@/assets/images/vision-y-estrategia.png')"
          />
          <v-card-title>¡Usuario con permisos insuficientes!</v-card-title>
          <v-card-text>
            <br>
            Favor de intertar con otra ruta o ir al
            <br>
            <v-btn @click="gotohome" variant="text">
              INICIO
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageNotFound",
  methods: {
    gotohome() {
      if (this.getStoreUser != "") {
        if (this.getStoreUser.boolean_admin_permissions === 1) {
          this.$router.push({ name: "EvaluatedInstances" });
        } else if (this.getStoreUser.boolean_admin_permissions === 0) {
          this.$router.push({ name: "EvaluationsList" });
        }
      }
    },
  },
  computed: {
    getStoreUser() {
      const userState = this.$store.state.user;
      if (userState != "") {
        const user = JSON.parse(userState);
        return user;
      } else {
        return "";
      }
    },
  },
};
</script>
