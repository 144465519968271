<template>
  <v-container fluid style="background-color: #282f33; color: #f5f5f5; font-size: xx-small;">
    <v-row justify="center" align="center">
      <v-col cols="12" sm="9" md="6" class="text-center py-1">
        Derechos Reservados Vision y Estrategia &copy; {{ new Date().getFullYear() }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppCopyRights",
};
</script>
