<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="9" md="6" class="text-center">
        <v-card elevation="3">
          <v-img
            class="mx-auto my-1"
            width="300px"
            aspect-ratio="16/9"
            :src="require('@/assets/images/vision-y-estrategia.png')"
          />
          <v-card-title>¡Error en la base de datos!</v-card-title>
          <v-card-text>
            <br>
            Favor de intertarlo más tarde
            <br>
            <v-btn @click="gotohome" variant="text">
              LOGIN
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DataBaseError",
  methods: {
    gotohome() {
      this.$router.push({ name: "Login" });
    },
  },
  computed: {},
};
</script>
