<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="9" md="6" class="text-center">
        <v-img
          class="mx-auto my-1"
          width="300px"
          aspect-ratio="16/9"
          :src="require('@/assets/images/vision-y-estrategia.png')"
        />
        <v-card elevation="3">
          <v-card-title>INGRESAR</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login" fast-fail ref="formLogin">
              <v-text-field
                v-model="username"
                label="Nombre de usuario"
                :prepend-icon="mdi.user"
                :rules="[rules.required, rules.onlyLettersNumbersAndDash]"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Contraseña"
                :prepend-icon="showPassword ? mdi.eye : mdi.eye_off"
                @click:prepend="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                :rules="[rules.required, rules.passwordLength]"
              ></v-text-field>
              <v-btn
                color="#561C24"
                elevation="3"
                variant="outlined"
                @click="login()"
                >Iniciar sesión</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { databaseLogin } from "@/assets/js/index.js";

export default {
  name: "LoginView",
  data() {
    return {
      logged_user: null,
      username: "",
      password: "",
      showPassword: false,
      mdi: {
        user: "mdi mdi-account-outline",
        eye: "mdi mdi-eye-outline",
        eye_off: "mdi mdi-eye-off-outline",
      },
      rules: {
        required: (v) => !!v || "Este campo es requerido",
        alphanumeric: (v) =>
          /^[a-zA-Z0-9.,\-_ ]*$/.test(v) ||
          "Este campo solo puede contener letras, números, puntos, comas, guiones medios, guiones bajos y espacios",
          onlyLettersNumbersAndDash: (v) =>
          /^[a-zA-Z0-9-_]*$/.test(v) ||
          "Este campo solo puede contener letras, números, guiones medios y guiones bajos",
        onlyLettersNumbers: (v) =>
          /^[A-Za-z0-9]+$/.test(v) ||
          "Este campo solo debe contener letras y números",
        passwordLength: (v) => v.length > 5 || 'La contraseña debe tener más de 5 caracteres',
      },
    };
  },
  mounted() {
    this.$store.commit("clearUser");
    sessionStorage.removeItem("filter_user_id");
    sessionStorage.removeItem("filter_isntance_name");
    sessionStorage.removeItem("filter_evaluation_id");
  },
  methods: {
    async login() {
      // validate form
      const isValid = await this.$refs.formLogin.validate();
      if (!isValid.valid) {
        window.alert("Verifica los datos sean correctos");
        return;
      }
      // database load data
      this.logged_user = await databaseLogin(
        this.$store.state.apiUrl +
          "login/" +
          this.username +
          "/" +
          this.password
      );
      //
      if (String(this.logged_user).includes("Failed to fetch")) {
        alert("Error en la base de datos para realizar el inicio de sesión");
        return;
      }
      if (typeof this.logged_user === "string") {
        if (this.logged_user == "user does not exist") {
          alert("El usuario no esta registrado");
        } else if (this.logged_user == "wrong password") {
          alert("Contraseña incorrecta");
        }
        return;
      } else if (this.logged_user) {
        this.$store.commit("setUser", JSON.stringify(this.logged_user[0]));
        if (this.logged_user[0].boolean_admin_permissions === 1) {
          this.$router.push({ name: "EvaluatedInstances" });
        } else if (this.logged_user[0].boolean_admin_permissions === 0) {
          sessionStorage.setItem("filter_user_id", this.logged_user[0].id);
          sessionStorage.setItem(
            "filter_instance_name",
            this.logged_user[0].instance_name
          );
          this.$router.push({ name: "EvaluationsList" });
        }
      }
    },
  },
};
</script>
